
import Vue from "vue";
import { isPast, formatDistanceStrict } from "date-fns";
import { CountdownHelper } from "@/helpers/countdownHelper";

interface ISingleEditCountdown {
  countdownComplete: boolean;
  dateForCountdown: string;
  countDownTimer: null | CountdownHelper;
}

export default Vue.extend({
  props: {
    deliveryDate: {
      type: [Date, String],
      default: ""
    }
  },
  mounted() {
    this.countDownTimer = new CountdownHelper(
      this.deliveryDate,
      this.setScheduledTimeFromNow,
      this.setCountdownComplete
    );
  },
  destroyed() {
    if (this.countDownTimer) {
      this.countDownTimer.stop();
    }
  },
  data(): ISingleEditCountdown {
    return {
      countdownComplete: false,
      dateForCountdown: "",
      countDownTimer: null
    };
  },
  methods: {
    setScheduledTimeFromNow(): void {
      const { deliveryDate } = this;
      if (!deliveryDate) return;
      const dt =
        typeof deliveryDate === "string"
          ? new Date(deliveryDate)
          : deliveryDate;

      if (isPast(dt)) {
        return;
      }

      this.dateForCountdown = formatDistanceStrict(new Date(dt), new Date(), {
        addSuffix: true
      });
    },
    setCountdownComplete() {
      this.countdownComplete = true;
    }
  }
});
